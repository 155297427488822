// export const baseurl = "http://localhost:2070"; //local harsh
// export const baseurl = "https://node-arappdev.mobiloitte.io";
// export const baseurl = "http://52.20.205.221:2070";
export const baseurl = "https://node.my-pitch.org";

const url = `${baseurl}/api`;

const ApiConfig = {
  //ADMIN AUTH
  login: `${url}/user/userLogin`,
  forgotPassword: `${url}/admin/resendOtp`,
  verifyOTP: `${url}/admin/verifyOTP`,
  resetPassword: `${url}/admin/resetPassword`,

  //ADMIN MANAGEMENT
  getAllUserFeedback: `${url}/admin/getAllUserFeedback`,
  getAllUserByType: `${url}/admin/getAllUserByType`,
  getUserBySpotifyId: `${url}/admin/getUserBySpotifyId`,
  getFeedbackBySpotifyId: `${url}/admin/getFeedbackBySpotifyId`,
  updateProfile: `${url}/admin/updateProfile`,
  getUser: `${url}/user/getUser`,
  changePassword: `${url}/admin/changePassword`,
  replyFeedback: `${url}/admin/replyFeedback`,

  //STATIC MANAGEMENT
  getAllStaticContentData: `${url}/static/getAllStaticContentData`,
  getStaticContentByType: `${url}/static/getStaticContentByType`,
  updateStaticContent: `${url}/static/updateStaticContent`,
  addStaticContent: `${url}/static/addStaticContent`,

  //SPOTIFY
  getSpotifyToken: `${url}/admin/getSpotifyToken`,
};
export default ApiConfig;
