import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Searchbar from "./Searchbar";
import typographyStyles from "../theme/Typography";
import ResponsiveDatePickers from "./DatePicker";
import Dropdown from "./Dropdown";
import Button from "./Button";

const filterBoxStyle = {
  container: {
    position: "relative",
    border: "1px solid lightgrey",
    padding: "7px",
    marginTop: "8px",
  },

  filterBoxcontent: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "flex-end",
  },

  filterBoxItem: {
    display: "flex",
    flexDirection: "column",
    margin: "8px",
  },

  filterlabel: {
    ...typographyStyles.h6,
    color: "black",
    position: "absolute",
    top: "-10px",
    left: "16px",
    backgroundColor: "white",
    padding: "0 4px",
    fontWeight: "bold",
  },
};

const FilterBox = ({
  searchbarPlaceholder,
  dropdownOptions,
  onFilterChange,
  defaultSelectedValue,
}) => {
  const [searchText, setSearchText] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const handleDropdownChange = (value) => {
    setSelectedValue(value);
    onFilterChange({ searchText, fromDate, toDate, selectedValue: value });
  };

  const handleClear = () => {
    setSearchText("");
    setFromDate(null);
    setToDate(null);
    setSelectedValue(defaultSelectedValue);
    onFilterChange({
      searchText: "",
      fromDate: null,
      toDate: null,
      selectedValue: defaultSelectedValue,
    });
  };

  return (
    <Box style={filterBoxStyle.container}>
      <Typography style={filterBoxStyle.filterlabel}>Filters</Typography>
      <Box style={filterBoxStyle.filterBoxcontent}>
        <Box style={filterBoxStyle.filterBoxItem}>
          <Searchbar
            placeholder={searchbarPlaceholder}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              onFilterChange({
                searchText: e.target.value,
                fromDate,
                toDate,
                selectedValue,
              });
            }}
          />
        </Box>
        <Box style={filterBoxStyle.filterBoxItem}>
          <ResponsiveDatePickers
            value={fromDate}
            label={"From Date"}
            onChange={(date) => {
              setFromDate(date);
              onFilterChange({ searchText, fromDate, toDate, selectedValue });
            }}
          />
        </Box>
        <Box style={filterBoxStyle.filterBoxItem}>
          <ResponsiveDatePickers
            value={toDate}
            label={"To Date"}
            onChange={(date) => {
              setToDate(date);
              onFilterChange({ searchText, fromDate, toDate, selectedValue });
            }}
          />
        </Box>
        <Box style={filterBoxStyle.filterBoxItem}>
          <Dropdown
            options={dropdownOptions}
            value={selectedValue}
            onChange={handleDropdownChange}
          />
        </Box>
        <Box style={filterBoxStyle.filterBoxItem}>
          <Button text="Reset Filter" onClick={handleClear} />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterBox;
