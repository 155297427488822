import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout/DashboardLayout";
import { Box, IconButton, Tooltip, CircularProgress } from "@mui/material";
import { BiBlock } from "react-icons/bi";
import { IoMdCopy } from "react-icons/io";
import FilterBox from "../../../components/FilterBox";
import BasicButtons from "../../../components/Button";
import PaginationTable from "../../../components/PaginationTable";
import urlimg from "../../../assets/profile_pic.jpeg";
import nodatafound from "../../../assets/datanotfound.png"
import { format } from "date-fns";
import "../../../scss/main.css";
import { RiEyeFill } from "react-icons/ri";
import { getAPIHandler, putAPIHandler } from "../../../ApiConfig/service";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const artistColumns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "thumbnail", label: "Image" },
  { id: "artist_name", label: "Artist Name" },
  { id: "artistSpotifyId", label: "Spotify ID" },
  { id: "genres", label: "Genres" },
  { id: "user_type", label: "User Type" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "status", label: "Status" },
  { id: "viewAction", label: "Action" },
];

const userColumns = [
  { id: "sr_no", label: "Sr.No." },
  { id: "thumbnail", label: "Image" },
  { id: "user_name", label: "User Name" },
  { id: "spotifyId", label: "Spotify ID" },
  { id: "user_type", label: "User Type" },
  { id: "created_date_time", label: "Created Date & Time" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

const dropdownOptionsForUserType = ["ARTIST", "USER"];

const UserListing = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [renderStatus, setRenderStatus] = useState(false);
  const [userType, setUserType] = useState("ARTIST");

  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData();
  }, [userType, renderStatus]);

  useEffect(() => {
    setData([]);
    setFilteredData([]);
  }, [userType]);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await getAPIHandler({
        endPoint: "getAllUserByType",
        paramsData: { userType },
      });
      const { userData = [], totalPages } = response.data.result;
      setData(userData);
      setFilteredData(userData);
      setTotalPages(totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

 

  const handleBlock = async (userId) => {
    try {
      const currentUser = data.find((item) => item._id === userId);
      if (!currentUser) {
        console.error("User not found in data.");
        return;
      }

      const newStatus =
        currentUser.status === "PENDING" ? "BLOCKED" : "PENDING";

      const response = await putAPIHandler({
        endPoint: "updateProfile",
        dataToSend: { userId, status: newStatus },
      });

      if (response.status === 200) {
        const updatedData = data.map((item) => {
          if (item._id === userId) {
            return { ...item, status: newStatus };
          }
          return item;
        });

        setData(updatedData);
        setRenderStatus(!renderStatus);
        toast.success(`User status updated successfully.`);
      } else {
        toast.error("Failed to update status. Please try again.");
      }
    } catch (error) {
      console.error("Error toggling status:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleViewClick = (spotifyId) => {
    let formattedSpotifyId = spotifyId;

    if (userType === "ARTIST" && spotifyId.length === 22) {
      formattedSpotifyId = `spotify:artist:${spotifyId}`;
    } else if (userType === "USER" && spotifyId.length === 28) {
      formattedSpotifyId = `spotify:user:${spotifyId}`;
    } else {
      console.warn(`Unexpected Spotify ID format: ${spotifyId}`);
    }

    navigate(`/UserListing/viewuser/${formattedSpotifyId}`);
  };

  const handleDownloadCSV = () => {
    const dataWithoutId = filteredData.map((row, index) => {
      const commonData = {
        sr_no: index + 1,
        thumbnail: userType === "ARTIST" ? row.images && row.images.length > 0 ? row.images[0].url : urlimg : "",
        created_date_time:
          format(new Date(row.createdAt), "yyyy/MM/dd HH:mm", {
            timeZone: "Asia/Kolkata",
          }) || "-",
        status: row.status,
      };
  
      if (userType === "ARTIST") {
        return {
          ...commonData,
          artist_name: row.artistName || "-",
          artistSpotifyId: row.spotifyId || "-",
          genres: row.genres && row.genres.length > 0 ? row.genres.join(", ") : "-",
        };
      } else {
        return {
          ...commonData,
          user_name: row.userName || "-",
          spotify_id: row.spotifyId || "-",
        };
      }
    });
  
    const headers = Object.keys(dataWithoutId[0]);
  
    let csvContent = headers.join(",") + "\n";
  
    csvContent += dataWithoutId
      .map((row) => headers.map((header) => row[header]).join(","))
      .join("\n");
  
    const timestamp = format(new Date(), "yyyy-MM-dd_HH:mm");
    const fileName = userType === "ARTIST" ? `Artist_list_${timestamp}.csv` : `User_list_${timestamp}.csv`;
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  
    saveAs(blob, fileName);
  };
  

  

  const handleFilterChange = (filterCriteria) => {
    let filtered = data;

    if (filterCriteria.searchText) {
      const searchField = userType === "ARTIST" ? "artistName" : "userName";
      filtered = filtered.filter((category) =>
        category[searchField]
          .toLowerCase()
          .includes(filterCriteria.searchText.toLowerCase().trim())
      );
    }

    const parseCustomDate = (romDate) => {
      const year = romDate.$y;
      const month = romDate.$M;
      const day = romDate.$D;
      const hours = romDate.$H;
      const minutes = romDate.$m;
      const seconds = romDate.$s;
      return new Date(year, month, day, hours, minutes, seconds);
    };

    if (filterCriteria.fromDate) {
      const fromDate = parseCustomDate(filterCriteria.fromDate);
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate >= fromDate;
      });
    }

    if (filterCriteria.toDate) {
      const toDate = parseCustomDate(filterCriteria.toDate);
      filtered = filtered.filter((category) => {
        const createdAtDate = new Date(category.createdAt);
        return createdAtDate <= toDate;
      });
    }

    if (filterCriteria.selectedValue) {
      filtered = filtered.filter(
        (category) => category.userType === filterCriteria.selectedValue
      );
      setUserType(filterCriteria.selectedValue);
    }

    setFilteredData(filtered);
  };

  const formatFollowers = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "k";
    } else {
      return count.toString();
    }
  };

  const handleCopyClick = (spotifyId) => {
    navigator.clipboard.writeText(spotifyId);
    toast.success(`Copied Spotify ID: ${spotifyId} to clipboard`);
  };

  const formattedData = (filteredData || []).map((row, index) => {
    const commonData = {
      sr_no: index + 1,
      thumbnail: (
        <img
          src={row.images && row.images.length > 0 ? row.images[0].url : urlimg}
          alt="thumbnail"
          width="65"
          height="35"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = urlimg;
          }}
        />
      ),
      user_type: row.userType || "-",
      created_date_time:
        format(new Date(row.createdAt), "yyyy/MM/dd HH:mm", {
          timeZone: "Asia/Kolkata",
        }) || "-",
      status: (
        <span
          style={{
            color:
              row.status === "ACTIVE"
                ? "green"
                : row.status === "PENDING"
                ? "orange"
                : "red",
          }}
        >
          {row.status}
        </span>
      ),
    };

    if (userType === "ARTIST") {
      return {
        ...commonData,
        artist_name: row.artistName || "-",
        artistSpotifyId: (
          <Box style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            {row.spotifyId}
            <Tooltip title="Copy Spotify ID">
              <IconButton onClick={() => handleCopyClick(row.spotifyId)}>
                <IoMdCopy style={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
        genres:
          row.genres && row.genres.length > 0 ? row.genres.join(", ") : "-",
        viewAction: (
          <Box className="row-button-container">
            {" "}
            <RiEyeFill
              onClick={() => {
                handleViewClick(row.spotifyId);
              }}
              style={{
                color: "rgb(0, 168, 215)",
                fontSize: "24px",
              }}
            />
          </Box>
        ),
        followers: formatFollowers(row.followers?.total || 0),
      };
    } else {
      return {
        ...commonData,
        user_name: row.userName || "-",
        spotifyId: (
          <Box style={{ display: "flex", alignItems: "center", gap: "2px" }}>
            {row.spotifyId}
            <Tooltip title="Copy Spotify ID">
              <IconButton onClick={() => handleCopyClick(row.spotifyId)}>
                <IoMdCopy style={{ fontSize: "18px" }} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
        action: (
          <Box className="row-button-container">
            <RiEyeFill
              onClick={() => {
                handleViewClick(row.spotifyId);
              }}
              style={{
                color: "rgb(0, 168, 215)",
                fontSize: "24px",
              }}
            />
            <BiBlock
              onClick={() => {
                handleBlock(row._id);
              }}
              style={{
                color:
                  row.status === "ACTIVE"
                    ? "green"
                    : row.status === "PENDING"
                    ? "orange"
                    : "red",
                fontSize: "24px",
              }}
            />
          </Box>
        ),
      };
    }
  });

  return (
    <DashboardLayout
      sectionName="User Management"
      showBackButtonSection={false}
      rightComponent={
        <BasicButtons text="Download CSV" onClick={handleDownloadCSV} />
      }
    >
      <Box className="category-container">
        <Box className="filter-container">
          <FilterBox
            searchbarPlaceholder={"Search by username/artistname... "}
            dropdownLabel={"User Type"}
            dropdownOptions={dropdownOptionsForUserType}
            defaultSelectedValue={"ARTIST"}
            onFilterChange={handleFilterChange}
          />
        </Box>
        {loading && (
          <CircularProgress
            size={58}
            style={{ marginLeft: 550, marginTop: 300 }}
          />
        )}

        {!loading && (
          <Box className="table-container">
        {filteredData.length === 0 ? (
          <Box className="no-data-found" style={{ textAlign: "center", padding: "20px" }}>
            <img src={nodatafound} alt="No Data Found" style={{width: "350px", height: "350px", margin: "25px"}} />
          </Box>
        ) : (
          <PaginationTable
            columns={userType === "ARTIST" ? artistColumns : userColumns}
            data={formattedData}
          />
        )}
        </Box>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default UserListing;
